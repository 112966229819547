"use client";
import React, { useEffect, useState } from "react";
export function MatadorChat() {
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        setIsClient(true);
    }, []);
    if (!isClient) return;
    return (
        <div
            className="matador-widget"
            data-id="6746230513e8a46801567e3c"></div>
    );
}
