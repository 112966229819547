"use client";
import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import Link from "next/link";

export default function HomeSearch({ className }: { className: string }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    return (
        <div
            className={`t-group t-relative ${className ?? ""}`}
            ref={dropdownRef}>
            <form
                className="t-flex t-items-center t-gap-1 t-max-w-2xl t-py-4 t-border t-border-gray-200 t-bg-white t-rounded-md group-focus-within:t-rounded-b-none group-focus-within:t-border-b-0 t-pl-2 t-mt-8"
                action="/cars"
                method="GET"
                onFocus={() => setIsOpen(true)}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="t-size-4">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    />
                </svg>
                <input
                    autoComplete="off"
                    className="t-h-full t-w-full t-flex-1 t-border-none t-bg-transparent t-text-base t-outline-none"
                    placeholder={`Search make, model, or keyword`}
                    type="text"
                    name="q"
                />
            </form>
            {isOpen && (
                <div className="t-flex t-flex-col t-absolute t-bg-white t-p-2 t-rounded-b-md t-border-bottom t-border-gray-200 t-w-full">
                    <div className="t-block t-text-sm t-font-semibold t-text[#2c074b]">Vehicle Types</div>
                    <div className="t-grid t-grid-cols-2 sm:t-grid-cols-4 t-gap-4">
                        <VehicleType
                            type="SUV"
                            img="/media/suv.png"
                        />
                        <VehicleType
                            type="Sedan"
                            img="/media/sedan.png"
                        />

                        <VehicleType
                            type="Truck"
                            img="/media/truck.png"
                        />
                        <VehicleType
                            type="Coupe"
                            img="/media/coupe.png"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

function VehicleType({ type, img }: { type: string; img: string }) {
    return (
        <div className="t-p-4 t-border t-border-gray-200 t-rounded-md hover:t-bg-gray-100">
            <Link href={`/cars?bodystyle=${type}`}>
                <Image
                    src={img}
                    width={117}
                    height={89}
                    className="t-w-full t-h-auto"
                    alt={type}
                />
                <div className="t-text-center t-text-cs t-font-semibold">{type}</div>
            </Link>
        </div>
    );
}
